import styles from './Loading.module.css';
import cn from 'classnames';

interface ILoading {
  percent: number
}

export const Loading = ({percent}: ILoading) => {
  return (
    <>
      <div className={cn(styles.loadingRow, styles.margin)}>
        <div className={styles.loading} style={{"width": `${percent}%`}}></div>
        <div className={styles.value}>{`${percent} %`}</div>
      </div>
    </>
  );
}