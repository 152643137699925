import {ReactComponent as CloseIcon} from '../../../assets/images/close.svg';

interface CloseProps {
  fill?: string;
  onClick?: () => void;
};

export const Close = ({fill, onClick}: CloseProps) => {
  return (
    <button
      onClick={onClick}
    >
      <CloseIcon style={{fill: fill}}/>
    </button>
  );
}