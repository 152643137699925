import React, {useContext} from 'react';
import {Button} from '../ui/Button';
import styles from './Hotels.module.css';
import {ModalContext} from '../Layout';
import {deleteHotel} from './api/deleteHotel';

interface HotelModalProps {
  children: React.ReactNode;
  deleteId: number;
}

export const HotelModal = ({children, deleteId}: HotelModalProps) => {

  const [modal, setModal] = useContext(ModalContext);

  const deleteHandler = async () => {
    await deleteHotel(deleteId)
    setModal('');
  }

  const cancelHandler = () => {
    setModal('');
  }

  return (
    <div>
      {children}
      <div className={styles.buttons}>
        <Button
          size={'small'}
          focus={true}
          onClick={deleteHandler}
        >
          Да
        </Button>
        <Button
          style={'red'}
          size={'small'}
          onClick={cancelHandler}
        >
          Нет
        </Button>
      </div>
    </div>
  );
}