import React from 'react';
import styles from './Table.module.css';


interface TableRowProps {
  children: React.ReactNode;
  key?: number | string;
}

export const TableRow = ({children}: TableRowProps) => {
  return(
    <tr className={styles.row}>
      {children}
    </tr>
  );
}