import styles from "./Button.module.css";
import React from 'react';
import cn from 'classnames';

export interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: any;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'normal';
  style?: 'green' | 'red';
  focus?: boolean;
};


export const Button = ({children, onClick, disabled, type, size, style, focus}: ButtonProps) => {
  return (
    <button
      autoFocus={focus}
      type={type ? type : 'button'}
      className={cn(
        styles.button,
        (disabled ? styles.disabled : ''),
        (size ? styles[size] : styles.normal),
        (style ? styles[style] : styles.green),
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
