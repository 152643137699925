import { AutorunForm } from "components/AutorunForm";
import classes from "./Autorun.module.css";

const Autorun = ({state}) => {
  return (
    <>
      <AutorunForm state={state}/>
    </>
  );
};

export { Autorun };
