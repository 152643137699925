import React, {FC, useState} from 'react';
import classes from './InputLabel.module.css';
import {InputLabelProps} from './InputLabel';
import {ReactComponent as Eye} from "assets/images/eye.svg";

export const TextInput: FC<InputLabelProps> = ({children, value, type, name, placeholder, min, max, readOnly}) => {
  const [inputType, setInputType] = useState<string>('text');
  const [inputValue, setInputValue] = useState<string | undefined>(value);

  const changeType = () => {
    inputType === "password" ? setInputType('text') : setInputType('password');
  };

  const changeHandler = (value: string) => {
    setInputValue(value);
  }

  return (
    <div className={classes.inputWrapper}>
      <input
        className={classes.input}
        // style={{width: widthValue}}
        name={name}
        placeholder={placeholder}
        type={inputType}
        min={min}
        max={max}
        value={inputValue}
        readOnly={readOnly}
        onChange={e => changeHandler(e.target.value)}
      />
      {type === "password" && (
        <div
          className={`${classes.icon} ${
            inputType === "password" ? classes.dark : classes.green
          }`}
        >
          <Eye onClick={changeType}/>
        </div>
      )}
    </div>
  );
}