import React from 'react';

interface MarginProps {
  children: React.ReactNode;
  offset: (number | 'auto')[];
}

export const Margin = ({children, offset}: MarginProps) => {
  const offsetString = offset.map(item => item === 'auto' ? 'auto' : item + 'px').join(' ');
  return (
    <div style={{
      margin: offsetString,
      maxWidth: 'fit-content',
    }}>
      {children}
    </div>
  )
}