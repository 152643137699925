import React from 'react';

export const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const formData = new FormData(e.target as HTMLFormElement);
  const body: Record<string, any> = {};

  for (let key of formData.keys()) {
    body[key] = formData.get(key);
  }

  const queryUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_UPDATE_HOTEL}`;
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_UPDATE_HOTEL}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return !!data;
}
