import {useEffect, useState} from 'react';

export const useUploadHotel = (id: string | number | undefined) => {
  const [value, setValue] = useState<any>(id);
  const [status, setStatus] = useState<string>('loading');

  const onChange = (e: number) => {
    setValue(e);
  }

  const onSubmit = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_UPDATE_HOTEL}`, {
      method: 'POST',
      body: JSON.stringify({
        index: value,
        action: 'uploadHotel',
      }),
    });
    const data: Record<string, any> = await response.json();
    console.log(data)
    setStatus(data.status);
  }


  return {value, status, onChange, onSubmit};
}
