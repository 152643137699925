import {BlockTitle} from "components/ui/BlockTitle";
import {InputLabel} from "components/ui/InputLabel";
import {SubmitButton} from "components/SubmitButton";
import {checkConfigAvailability} from '../../utils/api/checkBackendAvailability';
import useLocalStorage from '../../utils/hooks/localstorage';

import classes from "./AutorunForm.module.css";
import {FormEvent, useEffect, useState} from 'react';
import cn from 'classnames';

interface AutorunFormProps {
  state: any;
}

export const AutorunForm = ({state}: AutorunFormProps) => {

  const [activeAleanForm, setActiveAleanForm] = useState(false);

  const createConfig = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const sendData: Record<string, FormDataEntryValue | null> = {};
    for (let key of formData.keys()) {
      sendData[key] = formData.get(key);
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/server/controller/setup/index.php/?createConfig=y`, {
      method: 'POST',
      body: JSON.stringify(sendData),
    })
    const data = await response.json();

    console.log(data)

    setActiveAleanForm(true);
  };

  const handler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    state('isConfigInstalled', true);
  }


  useEffect(() => {
    (async () => checkConfigAvailability())();
  }, [])

  return (
    <>

      <form
        onSubmit={createConfig}
        className={classes.form}
      >
        <div className={classes.block}>
          <BlockTitle>База данных</BlockTitle>
          <InputLabel name="host" type="text" placeholder="Хост"/>
          <InputLabel name="userName" type="text" placeholder="Имя пользователя"/>
          <InputLabel name="dbName" type="text" placeholder="Название базы данных"/>
          <InputLabel name="password" type="password" placeholder="Пароль"/>
        </div>
        <SubmitButton type="submit">
          Запустить
        </SubmitButton>
      </form>

      <form
        className={cn(
          classes.form,
          !activeAleanForm ? classes.disableForm : '',
        )}

      >
        <div className={classes.block}>
          <BlockTitle>Доступы к Alean</BlockTitle>
          <InputLabel
            name="aleanUsername"
            type="text"
            placeholder="Логин"
            readOnly={!activeAleanForm}
          />
          <InputLabel
            name="aleanPassword"
            type="password"
            placeholder="Пароль"
            readOnly={!activeAleanForm}
          />
        </div>
        <SubmitButton
          type="submit"
          disabled={!activeAleanForm}
        >
          Запустить
        </SubmitButton>
      </form>
    </>
  );
};
