import {useState, useEffect, useContext} from "react";
import {BlockTitle} from "components/ui/BlockTitle";
import {SubmitButton} from "components/SubmitButton";
import {Loading} from "components/ui/Loading";
import {InlineMessage} from "components/ui/InlineMessage";
import {InputLabel} from "components/ui/InputLabel";
import {TitleContext} from '../../components/Layout';
import {UploadHotelById} from '../../components/UploadHotels';

type UploadStatus = 'default' | 'upload' | 'success' | 'warning' | 'error';

interface UploadResponse {
  index: number;
  status: string;
  hotelsCount: number;
  message: string;
}

const messages: Record<UploadStatus, string> = {
  'success': 'Отели успешно загружены',
  'warning': 'Отели частично загружены',
  'error': 'При загрузке отелей произошла ошибка',
  'default': '',
  'upload': '',
}

export const HotelsUpload = () => {
  const [percent, setPercent] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>("default");
  const [chunkSize, setChunkSize] = useState(1);
  const [title, setTitle] = useContext(TitleContext);


  useEffect(() => {
    setPercent(0);
    setTitle('Загрузка отелей');
  }, []);

  const handleInputChange = (value: number) => {
    setChunkSize(value);
  }

  const handleUpload = async (index: number): Promise<void> => {
    const body = {
      action: 'uploadHotels',
      index: index,
      params: {
        'checkDuplicates': true
      }
    };

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_UPLOAD_HOTELS}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
    const data: UploadResponse = await response.json();
    let {index: newIndex, status, hotelsCount, message} = data;

    if (status === 'error') {
      setPercent(100);
      setUploadStatus('error');

      return;
    }

    if (status === 'stop') {
      setPercent(100);
      setUploadStatus('warning');

      return;
    }

    if (status === 'success') {
      setPercent(100);
      setUploadStatus('success');

      return;
    }

    if (status === 'continue') {
      const onePercent = Math.ceil(hotelsCount / 100);

      if (index % onePercent === 0) {
        setPercent((percent) => percent + 1);
      }

      await handleUpload(newIndex);
    }

  };

  const handleStartUpload = async () => {
    setPercent(1);
    setUploadStatus('upload');

    await handleUpload(2126);
  };

  return (
    <>
      <section>
        <UploadHotelById/>

      </section>

      <section>
        <BlockTitle>
          Выгрузить все отели из Алеана
          {messages[uploadStatus] && (
            <InlineMessage type={uploadStatus}>
              {messages[uploadStatus]}
            </InlineMessage>
          )}
        </BlockTitle>

        <InputLabel
          min={1}
          max={10}
          value={'1'}
          type="number"
          onChange={handleInputChange}
        >
          Количество загружаемых отелей за шаг
        </InputLabel>

        <SubmitButton
          onClick={handleStartUpload}
          disabled={uploadStatus === "upload" ? true : false}
        >
          Начать выгрузку
        </SubmitButton>

        {!!percent && <Loading percent={percent}/>}
      </section>
    </>
  );
};
