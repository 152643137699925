import {FC, useContext, useEffect, useState} from 'react';
import {EditForm} from '../ui/EditForm';
import {InputLabel} from '../ui/InputLabel';
import {IHotel} from '../../types/hotel';
import {handleSubmit} from './api/updateHotels';
import {useParams} from 'react-router-dom';
import {TitleContext} from '../Layout';
import {getHotels} from './api/getHotels';


export const EditHotels: FC = () => {

  const [hotel, setHotels] = useState<IHotel>();
  const {id} = useParams();
  const [title, setTitle] = useContext(TitleContext);

  useEffect(() => {
    (async () => {
      const hotelData = await getHotels(Number(id));
      setTitle(`Отель ${hotelData.name}`);
      setHotels(hotelData);
    })();
  }, []);


  return (
    <>
      {!hotel && "Пусто"}
      {hotel &&
          <EditForm
              onSubmit={handleSubmit}
          >
              <input name={'id'} value={hotel.id} type="hidden"/>

              <img src={hotel.image}/>

              <InputLabel
                  name={'name'}
                  value={hotel.name}
                  type="text"
              >
                  Название отеля
              </InputLabel>

              <InputLabel
                  name={'top'}
                  value={hotel.top}
                  type="text"
              >
                  Отображать в топе
              </InputLabel>

              <InputLabel
                  name={'address'}
                  value={hotel.address}
                  type="text"
              >
                  Адрес
              </InputLabel>

              <InputLabel
                  name={'resort'}
                  value={hotel.resort}
                  type="text"
              >
                  Направление
              </InputLabel>

              <InputLabel
                  name={'town_name'}
                  value={hotel.town_name}
                  type="text"
              >
                  Город
              </InputLabel>

              <InputLabel
                  name={'area_name'}
                  value={hotel.area_name}
                  type="text"
              >
                  Область
              </InputLabel>

              <InputLabel
                  name={'description'}
                  value={hotel.description}
                  type="textareao"
              >
                  Описание
              </InputLabel>

              <InputLabel
                  name={'stars'}
                  value={hotel.stars}
                  type="text"
              >
                  Количество звезд
              </InputLabel>

              <InputLabel
                  name={'beach'}
                  value={hotel.beach}
                  type="textarea"
              >
                  Пляж
              </InputLabel>

              <InputLabel
                  name={'children'}
                  value={hotel.children}
                  type="textarea"
              >
                  Дети
              </InputLabel>

              <InputLabel
                  name={'coordinates'}
                  value={hotel.coordinates}
                  type="text"
              >
                  Координаты
              </InputLabel>

              <InputLabel
                  name={'distance'}
                  value={hotel.distance}
                  type="textarea"
              >
                  Расположение
              </InputLabel>

              <InputLabel
                  name={'how_to_reach'}
                  value={hotel.how_to_reach}
                  type="textarea"
              >
                  Как добраться
              </InputLabel>

              <InputLabel
                  name={'infrastructure'}
                  value={hotel.infrastructure}
                  type="textarea"
              >
                  Инфраструктура
              </InputLabel>

              <InputLabel
                  name={'meal'}
                  value={hotel.meal}
                  type="textarea"
              >
                  Питание
              </InputLabel>

              <InputLabel
                  name={'price_includes'}
                  value={hotel.price_includes}
                  type="textarea"
              >
                  Цена включает
              </InputLabel>

              <InputLabel
                  name={'spa'}
                  value={hotel.spa}
                  type="textarea"
              >
                  Спа
              </InputLabel>

              <InputLabel
                  name={'work_period'}
                  value={hotel.work_period}
                  type="text"
              >
                  Период работы
              </InputLabel>
          </EditForm>
      }
    </>
  );
}