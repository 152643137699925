import React from 'react';

interface TableHeadProps {
  children: React.ReactNode;
}

export const TableHead = ({children}: TableHeadProps) => {
  return (
    <thead>
      {children}
    </thead>
  );
}