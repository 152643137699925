import React, {ChangeEvent, FC, useState} from 'react';
import classes from './InputLabel.module.css';
import {InputLabelProps} from './InputLabel';
import cn from 'classnames';

export const NumberInput: FC<InputLabelProps> = ({children, type, name, placeholder, min, max, width, value, onChange}) => {
  const [inputValue, setInputValue] = useState<string | undefined>(value);

  const changeHandler = (eventValue: string) => {
    let value = Number(eventValue);

    if(max && Number(eventValue) > max) {
      value = max;
    }

    setInputValue(String(value));
    onChange && onChange(value);
  }

  return (
    <div className={classes.inputWrapper}>
      <input
        className={cn(classes.input, classes.center)}
        style={{width: width ? width : '50px'}}
        name={name}
        placeholder={placeholder}
        type={'number'}
        min={min}
        max={max}
        value={inputValue}
        onChange={e => changeHandler(e.target.value)}
      />
    </div>
  );
}