import {Pagination, Placeholder, Table, TableBody, TableCol, TableHead, TableRow} from '../ui/Table';
import {useContext, useEffect, useState} from 'react';
import {IHotel} from '../../types/hotel';
import {ModalContext, TitleContext} from '../Layout';
import {HotelItem} from './HotelItem';
import {Modal} from '../ui/Modal';
import {HotelModal} from './HotelModal';

type Data = {
  count: number;
  hotels: IHotel[];
}


export const HotelsList = () => {

  const [hotels, setHotels] = useState<IHotel[]>();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState(15);
  const [pagesCount, setPagesCount] = useState<number>();
  const [title, setTitle] = useContext(TitleContext);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [modal] = useContext(ModalContext);


  useEffect(() => {
    setTitle('Отели');
  }, [])

  const getHotelsForPage = async (page = 0, limit = 10): Promise<[number, IHotel[]]> => {
    const params = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });
    const queryUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_HOTELS_PAGINATION}?${params}`;
    const response = await fetch(queryUrl, {
      method: 'GET',
    });
    const {count, hotels}: Data = await response.json();

    return [count, hotels];
  }

  useEffect(() => {
    (async () => {
      const [count, hotels] = await getHotelsForPage(page, limit);
      const pages = Math.floor(count / limit);

      setPagesCount(pages);

      setHotels(hotels);
    })()
  }, [limit]);

  const changePage = async (type: string) => {
    setHotels([]);
    const neededPage = type === 'next' ? page + 1 : page - 1;
    const [count, hotels] = await getHotelsForPage(neededPage, limit);
    setHotels(hotels);
    setPage(neededPage);
  }

  const changeLimit = async (value: number) => {
    setHotels([]);
    setLimit(value);
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCol
              width={70}
            >
              ID
            </TableCol>
            <TableCol>Название</TableCol>
            <TableCol
              width={100}
            >
              Alean ID
            </TableCol>
            <TableCol
              width={70}
            >
            </TableCol>
          </TableRow>
        </TableHead>
        <TableBody>
          {!hotels?.length && Array.from({length: limit}).map((_, index) => (
            <TableRow key={index}>
              <TableCol><Placeholder/></TableCol>
              <TableCol><Placeholder/></TableCol>
              <TableCol><Placeholder/></TableCol>
              <TableCol><Placeholder/></TableCol>
            </TableRow>
          ))
          }
          {hotels && hotels.map((hotel) => (
            <HotelItem deleteState={setDeleteId} key={hotel.name} {...hotel}/>
          ))}
        </TableBody>
      </Table>

      <Pagination
        pageHandler={changePage}
        limitHandler={changeLimit}
        pagesCount={pagesCount}
        page={page}
        limit={limit}
        possibleLimit={[15, 30, 60]}
      />

      {!!modal &&
          <Modal>
              <HotelModal
                deleteId={deleteId}
              >
                {modal}
              </HotelModal>
          </Modal>
      }
    </>
  );
}