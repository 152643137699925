import {useParams} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {IHotel} from '../../types/hotel';
import {BackLink} from '../../components/ui/BackLink';
import {TitleContext} from '../../components/Layout';
import {EditHotels} from '../../components/EditHotels/EditHotels';


export const Hotel = () => {

  return (
    <>
      <BackLink/>
      <EditHotels/>
    </>
  );
};