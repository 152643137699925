import React, {useState} from "react";
import classes from "./InputLabel.module.css";
import {NumberInput} from './NumberInput';
import {TextInput} from './TextInput';
import {TextArea} from './TextArea';

export interface InputLabelProps {
  children?: React.ReactNode;
  type?: string;
  name?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  width?: string;
  value?: string;
  readOnly?: boolean;
  onChange?: (value: number) => void;
}

export const InputLabel = (props: InputLabelProps) => {
  const {children, type} = props;

  let component: React.ReactNode;

  switch (type) {
    case 'number':
      component = <NumberInput {...props}/>;
      break;
    case 'text':
      component = <TextInput {...props}/>;
      break;
    case 'textarea':
      component = <TextArea {...props}/>;
      break;
    default:
      component = <TextInput {...props}/>;
      break;
  }

  return (
    <label className={classes.label}>
      {children && <span className={classes.span}>{children}</span>}

      {component}

    </label>
  );
};
