import React from 'react';
import styles from './Table.module.css';
import {Link} from 'react-router-dom';

interface TableColProps {
  children?: React.ReactNode;
  link?: string;
  width?: number;
}

export const TableCol = ({children, link, width}: TableColProps) => {

  return (
    <td style={{width: width + 'px'}} className={styles.col}>
      {link ?
        <Link to={link} className={styles.text}>
          {children}
        </Link>
        :
        <span className={styles.text}>{children}</span>
      }
    </td>
  )
}