import {Routes, Route, Link} from "react-router-dom";
import {Home} from 'pages/Home';
import {Test} from "pages/Test/Test";
import {HotelsUpload} from "pages/HotelsUpload";
import {Layout} from "components/Layout";
import {Hotels} from './pages/Hotels/Hotels';
import {Hotel} from './pages/Hotel/Hotel';
import {Autorun} from './pages/Autorun/Autorun';
import useLocalStorage from './utils/hooks/localstorage';

import "./App.css";
import {useEffect, useState} from 'react';



function App() {


  const {value: isConfigInstalled, setLocalStorage, getLocalStorage, removeLocalStorage} = useLocalStorage('isConfigInstalled');

  useEffect(() => {

    setLocalStorage('isConfigInstalled', false)

  }, [])

  return (
    <>
      {!isConfigInstalled &&
          <Routes>
              <Route index path="/" element={<Autorun state={setLocalStorage} />}/>
          </Routes>
      }

      {isConfigInstalled &&
          <Routes>
              <Route path="/" element={<Layout/>}>
                  <Route index element={<Home/>}/>
                  <Route path="test/" element={<Test/>}/>
                  <Route path="hotels-upload/" element={<HotelsUpload/>}/>
                  <Route path="hotels/" element={<Hotels/>}/>
                  <Route path="hotels/:id" element={<Hotel/>}/>
              </Route>
          </Routes>
      }
    </>
  );
}

export default App;
