import {Link, Outlet} from "react-router-dom";
import {PageTitle} from "components/ui/PageTitle";
import styles from "./Layout.module.css";
import {createContext, useState} from 'react';

export const TitleContext = createContext<any>('');
export const ModalContext = createContext<any>('');

export const Layout = () => {

  const [title, setTitle] = useState('');
  const [modal, setModal] = useState('');

  return (
    <>
      <ModalContext.Provider value={[modal, setModal]}>
        <div className={styles.wrapper}>
          <aside className={styles.sidebar}>
            <nav className={styles.navigation}>
              <ul>
                <li>
                  <Link to={'/hotels-upload/'}>Загрузка отелей</Link>
                </li>
                <li>
                  <Link to={'/hotels/'}>Список отелей</Link>
                </li>
              </ul>
            </nav>
          </aside>
          <TitleContext.Provider value={[title, setTitle]}>
            <div className={styles.content}>
              <main className={styles.main}>
                <header>
                  <PageTitle>{title}</PageTitle>
                </header>
                <Outlet/>
              </main>
              <footer className={styles.footer}></footer>
            </div>
          </TitleContext.Provider>
        </div>
      </ModalContext.Provider>
    </>
  );
};
