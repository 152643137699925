import {InputLabel} from '../ui/InputLabel';
import {BlockTitle} from '../ui/BlockTitle';
import {useUploadHotel} from './api/uploadHotel';
import {Button} from '../ui/Button';

export const UploadHotelById = () => {

  const {value, onChange, onSubmit} = useUploadHotel('');

  return (
    <form>
      <BlockTitle>
        Загрузка отеля по Alean Id
      </BlockTitle>
      <InputLabel
        width={'70px'}
        min={0}
        type={'number'}
        value={String(value)}
        placeholder={'id'}
        onChange={(e) => onChange(e)}
      >
        Alean Id отеля
      </InputLabel>
      <Button
        onClick={onSubmit}
      >
        Загрузить
      </Button>
    </form>
  );
}