import React from 'react';
import {ButtonProps} from '../ui/Button';
import {Button} from '../ui/Button';


export const SubmitButton = ({children, onClick, disabled}: ButtonProps) => {
  return (
    <Button
      type={'submit'}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
