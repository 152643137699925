import React, {FC} from 'react';
import styles from './InputLabel.module.css';
import {InputLabelProps} from './InputLabel';

export const TextArea: FC<InputLabelProps> = ({children, name, value}) => {
  return (
    <textarea
      className={styles.textarea}
      name={name}
      defaultValue={value}
    >
    </textarea>
  );
}