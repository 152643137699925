import styles from './Modal.module.css';
import React, {useContext} from 'react';
import {ModalContext} from '../../Layout';
import {Close} from '../Close';

interface ModalProps {
  children: React.ReactNode;
}

export const Modal = ({children}: ModalProps) => {
  const [modal, setModal] = useContext(ModalContext);

  const hideModal = () => {
    setModal('');
  };


  return (
    <div
      className={styles.overlay}
      onClick={hideModal}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles.modal}
      >
        <div className={styles.modalClose}>
          <Close
            onClick={hideModal}
            fill={'var(--errorColor)'}
          />
        </div>
        {children}
      </div>
    </div>
  );
}