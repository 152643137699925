import {Link, useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as Arrow} from '../../../assets/images/arrow.svg';
import styles from './BackLink.module.css';

interface BackLinkProps {
  children?: React.ReactNode;
}

export const BackLink = ({children}: BackLinkProps) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className={styles.link}
    >
      <Arrow/>
      {children ? children : 'Назад'}
    </button>
  );
}