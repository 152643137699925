import React from 'react';
import styles from './EditForm.module.css';
import {SubmitButton} from '../../SubmitButton';

interface EditFormProps {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<boolean>;
}

export const EditForm = ({children, onSubmit}: EditFormProps) => {
  return (
    <form
      className={styles.form}
      onSubmit={onSubmit}
    >
      <div className={styles.formInner}>
        {children}
      </div>
      <SubmitButton>
        Сохранить
      </SubmitButton>
    </form>
  );
};