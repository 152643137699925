import {TableCol, TableRow} from '../ui/Table';
import {Margin} from '../ui/Margin';
import {Close} from '../ui/Close';
import {IHotel} from '../../types/hotel';
import {FC, useContext} from 'react';
import {deleteHotel} from './api/deleteHotel';
import {ModalContext} from '../Layout';

interface HotelItemProps extends IHotel{
  deleteState: any;
}

export const HotelItem: FC<HotelItemProps> = ({id, name, alean_id, deleteState}) => {

  const [modal, setModal] = useContext(ModalContext);

  const handlerDelete = async (id: number, name: string) => {
    deleteState(id);
    setModal(`Вы уверенны, что хотите удалить отель ${name} c id: ${id}`);
  };

  return (
    <TableRow>
      <TableCol link={`/hotels/${id}`}>{id}</TableCol>
      <TableCol link={`/hotels/${id}`}>{name}</TableCol>
      <TableCol>{alean_id}</TableCol>
      <TableCol>
        <Margin
          offset={[0, 'auto']}
        >
          <Close
            onClick={() => handlerDelete(id, name)}
            fill={'var(--errorColor)'}
          />
        </Margin>
      </TableCol>
    </TableRow>
  );
}