import {useState} from 'react';

type TValue = string | number | object | boolean;

const useLocalStorage = (key: string) => {

  const getLocalStorage = (key: string) => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : '';
  };

  const [value, setValue] = useState<TValue>(getLocalStorage(key));

  const setLocalStorage = (key: string, value: TValue) => {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  }

  const removeLocalStorage = (key: string) => {
    localStorage.removeItem(key);
    setValue('');
  }

  return {value, setLocalStorage, getLocalStorage, removeLocalStorage};
}

export default useLocalStorage;
