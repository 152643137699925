import styles from './Table.module.css';
import {ReactComponent as Arrow} from "assets/images/arrow.svg";

import cn from 'classnames';

interface PaginationProps {
  // children?: React.ReactNode;
  page: number;
  pagesCount?: number;
  limit?: number;
  possibleLimit?: number[];
  pageHandler: any;
  limitHandler: any;
}

export const Pagination = ({page, pagesCount, limit, possibleLimit, pageHandler, limitHandler}: PaginationProps) => {
  return (
    <div className={styles.pagination}>
      <div className={styles.limitsWrapper}>
        <span>Отображать на странице:</span>
        <ul className={styles.limits}>
          {possibleLimit?.map(lt => (
            <li key={`limit_${lt}`}>
              <button
                className={cn(lt === limit && styles.limitChecked)}
                onClick={() => limitHandler(lt)}
              >
                {lt}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.arrowWrapper}>
        <button
          className={cn(
            styles.arrow,
            !page && styles.arrowDisabled,
            styles.arrowPrev
          )}
          onClick={() => page !== 0 && pageHandler('prev')}
        >
          <Arrow/>
        </button>
        <span>
        {page} / {pagesCount}
      </span>
        <button
          className={cn(
            styles.arrow,
            page === pagesCount && styles.arrowDisabled,
            styles.arrowNext
          )}
          onClick={() => page !== pagesCount && pageHandler('next')}
        >
          <Arrow/>
        </button>
      </div>
    </div>
  );
}