import styles from './Home.module.css';
import {Link} from 'react-router-dom';
import {FC} from 'react';

export const Home = () => {
  return (
    <>

    </>
  );
};
