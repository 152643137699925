import {BackLink} from '../../components/ui/BackLink';
import {HotelsList} from '../../components/Hotels';

export const Hotels = () => {
  return (
    <>
      <BackLink/>
      <HotelsList/>
    </>
  );
}